import { defineStore } from "pinia";
import type { ICompaniesStore } from "~~/utils/interfaces/companies-interfaces";
import { useCompaniesApi } from "~~/composables/companies/useCompaniesApi";

export const useCompaniesStore = defineStore("companies", {
  state: (): ICompaniesStore => {
    return {
      legal_entities: null,
      company_groups: null,
      company_infos: null,
      all_companies: null,
    };
  },
  getters: {},
  actions: {
    async fetchLegalEntities() {
      const companiesApi = useCompaniesApi();
      const { data } = await companiesApi.fetchCompanyLegalEntities();

      if (data.value) {
        this.legal_entities = data.value;
      }
    },

    async fetchCompanyGroups() {
      const companiesApi = useCompaniesApi();
      const { data } = await companiesApi.fetchCompanyGroups();
      if (data.value) {
        this.company_groups = data.value;
      }
    },

    async fetchCompanyInfos() {
      const companiesApi = useCompaniesApi();
      const { data } = await companiesApi.fetchCompanyInfos();
      if (data.value) {
        this.company_infos = data.value;
      }
    },

    async fetchAdminAllCompanies() {
      const companiesApi = useCompaniesApi();
      const { data } = await companiesApi.fetchAdminAllCompanies();

      if (data.value) {
        this.all_companies = data.value;
      }
    },
  },
});
